import * as Yup from 'yup';

export const promocodeDefaultValues = {
  promo_code: '',
  discount_value: '',
  expiry_date: '',
  status: ''
};

export const promocodeSchema = Yup.object().shape({
  promo_code: Yup.string()
    .min(6, 'Promo code must be at least 6 characters')
    .max(12, 'Promo code must be at most 12 characters')
    .required('Promo code is required'),
  discount_value: Yup.string()
    .required('Discount percentage is required')
    .test('max', 'Discount percentage cannot be greater than 70', (value) => {
      if (!value) return true;
      const discount = parseFloat(value);
      return discount <= 70;
    }),
  expiry_date: Yup.string().required('Expiry date is required'),
  status: Yup.string().required('Status is required')
});
