const actions = {
  LOAD_PROMOCODES_LIST: 'LOAD_PROMOCODES_LIST',
  LOAD_PROMOCODES_LIST_SUCCESS: 'LOAD_PROMOCODES_LIST_SUCCESS',
  SAVE_PROMOCODES_LIST: 'SAVE_PROMOCODES_LIST',
  TOGGLE_LOADING: 'PROMOCODES_TOGGLE_LOADING',
  TOGGLE_API_HANDLE_MODAL: 'PROMOCODES_TOGGLE_API_HANDLE_MODAL',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_API_HANDLE_MODAL,
    payload: { data }
  }),

  loadPromocodesApi: (pagination, data) => {
    return { type: actions.LOAD_PROMOCODES_LIST, pagination, data };
  },
  loadPromocodesApiSuccess: (pagination, data) => ({
    type: actions.LOAD_PROMOCODES_LIST_SUCCESS,
    payload: { pagination, data }
  })
};

export default actions;
