import actions from './actions';
import { promocodeDefaultValues } from '@iso/schemasAndValidations/Promocode';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  promocodeList: [],
  promocode: promocodeDefaultValues
};

export default function promocodeReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_PROMOCODES_LIST:
      return {
        ...state,
        promocodeList: action.data
      };
    case actions.LOAD_PROMOCODES_LIST_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        promocodeList: action.payload.data,
        errorMessage: false
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload.value
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        promocode: action.payload.data || initState.promocode
      };
    default:
      return state;
  }
}
