import authSagas from '@iso/redux/auth/saga';
import faq from '@iso/redux/faqManagement/sagas';
import profileSaga from '@iso/redux/profile/saga';
import staticPage from '@iso/redux/staticPage/sagas';
import { all } from 'redux-saga/effects';
import ToolUsageSaga from '@iso/redux/toolUsage/sagas';
import promocodeSaga from '@iso/redux/promocode/sagas';
import subscriptionSaga from '@iso/redux/subscriptionManagement/sagas';
import userSaga from '@iso/redux/userManagement/sagas';
import tokenSaga from '@iso/redux/tokenManagement/sagas';
import paymentSaga from '@iso/redux/paymentManagement/sagas';
import helpVideosSaga from '@iso/redux/helpVideosManagement/sagas';

/* eslint-disable no-unused-vars */
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    faq(),
    profileSaga(),
    staticPage(),
    ToolUsageSaga(),
    promocodeSaga(),
    subscriptionSaga(),
    userSaga(),
    paymentSaga(),
    helpVideosSaga(),
    tokenSaga()
  ]);
}
