const actions = {
  LOAD_TOKEN_LIST: 'LOAD_TOKEN_LIST',
  LOAD_TOKEN_LIST_SUCCESS: 'LOAD_TOKEN_LIST_SUCCESS',
  SAVE_TOKEN_LIST: 'SAVE_TOKEN_LIST',

  LOAD_API_ERROR: 'LOAD_API_ERROR',
  SAVE_API_ERROR: 'SAVE_API_ERROR',

  TOGGLE_LOADING: 'TOGGLE_LOADING',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadTokenApi: (pagination, data) => {
    return { type: actions.LOAD_TOKEN_LIST, pagination, data };
  },
  loadTokenApiSuccess: (pagination, data) => ({
    type: actions.LOAD_TOKEN_LIST_SUCCESS,
    payload: { pagination, data }
  }),
  loadApiError: (error) => ({
    type: actions.LOAD_API_ERROR,
    payload: { error }
  }),
  saveApiError: (error) => ({
    type: actions.SAVE_API_ERROR,
    payload: { error }
  })
};

export default actions;
