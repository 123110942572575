import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadToken(payload) {
  try {
    yield put(actions.loadTokenApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}
function* storeTokenApi() {
  try {
    yield put({ type: actions.LOAD_TOKEN_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TOKEN_LIST, loadToken),
    takeEvery(actions.SAVE_TOKEN_LIST, storeTokenApi)
  ]);
}
