import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import FAQManagement from '@iso/redux/faqManagement/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import { combineReducers } from 'redux';
import StaticPage from '@iso/redux/staticPage/reducer';
import ToolUsage from '@iso/redux/toolUsage/reducer';
import Promocodes from '@iso/redux/promocode/reducer';
import Subscription from '@iso/redux/subscriptionManagement/reducer';
import UserManagement from '@iso/redux/userManagement/reducer';
import TokenManagement from '@iso/redux/tokenManagement/reducer';
import PaymentManagement from '@iso/redux/paymentManagement/reducer';
import HelpVideosManagement from '@iso/redux/helpVideosManagement/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  FAQManagement,
  StaticPage,
  ToolUsage,
  Promocodes,
  Subscription,
  UserManagement,
  PaymentManagement,
  HelpVideosManagement,
  TokenManagement
});
