import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  tokenList: []
};

export default function tokenManagementReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_TOKEN_LIST:
      return {
        ...state,
        tokenList: action.data
      };
    case actions.LOAD_TOKEN_LIST_SUCCESS:
      return {
        ...state,
        tokenList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    default:
      return state;
  }
}
